import { shallowEqual, useSelector } from 'react-redux';

import { IApplicationState } from '../store';
import {
  // hideBLSessionsFlag,
  hideULJPhase2Flag,
  isMaintenanceModeFlag,
  isSearchFeatureEnabled,
  isSigninDisableFlag,
  showNewTimezoneFlag,
  disableSessionTimeout,
  disableMultiRoleAndFiltersInUrl,
  extraSearchODataFilters,
  disableResendInvitationLink,
  disableBLTeamsJoinLink,
  disableTermsAndConditions,
  disableMSLearnSSOForCertificationPage,
  disableMSLearnSSOForDeliveriesPage,
  disableMSLearnSSOForLandingPageAndNavs,
  disableLxpSSOFlag,
  disableProjectReadinessFlag,
  disableFrenchEpicFlag,
  disableGermanEpicFlag,
  enableCopilotV2,
  disableELAUnAuthFlowFlag
} from './Constants';

const FeatureFlagValues = () => {
  const [featureflaglist] = useSelector((state: IApplicationState) => [state.FeatureFlagStore?.featureFlags], shallowEqual);

  const isSigninDisable =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === isSigninDisableFlag)?.map(m => m?.isEnabledFlag)[0];

  const isMaintenanceMode = featureflaglist && featureflaglist?.filter(d => d?.flagName === isMaintenanceModeFlag);

  const searchFeature =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === isSearchFeatureEnabled)?.map(m => m?.isEnabledFlag)[0];

  const showNewTimezone =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === showNewTimezoneFlag)?.map(m => m?.isEnabledFlag)[0];

  const hideSessionTimeout =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableSessionTimeout)?.map(m => m?.isEnabledFlag)[0];

  const isMultiRoleAndFiltersInURLDisabled =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableMultiRoleAndFiltersInUrl)?.map(m => m?.isEnabledFlag)[0];

  const hideULJPhase2 = featureflaglist && featureflaglist?.filter(d => d?.flagName === hideULJPhase2Flag)?.map(m => m?.isEnabledFlag)[0];

  const isExtraSearchODataFiltersEnabled =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === extraSearchODataFilters)?.map(m => m?.isEnabledFlag)[0];

  // const hideBLSessions = featureflaglist && featureflaglist?.filter(d => d?.flagName === hideBLSessionsFlag)?.map(m => m?.isEnabledFlag)[0];
  const hideBLSessions = false;
  const isResendInvitationLink =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableResendInvitationLink)?.map(m => m?.isEnabledFlag)[0];
  const isBLTeamsJoinLink =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableBLTeamsJoinLink)?.map(m => m?.isEnabledFlag)[0];

  const disableEsiTerms =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableTermsAndConditions)?.map(m => m?.isEnabledFlag)[0];

  const disableLearnSSOForCertificationPage =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableMSLearnSSOForCertificationPage)?.map(m => m?.isEnabledFlag)[0];

  const disableLearnSSOForDeliveriesPage =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableMSLearnSSOForDeliveriesPage)?.map(m => m?.isEnabledFlag)[0];

  const disableLearnSSOForLandingPageAndNavs =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableMSLearnSSOForLandingPageAndNavs)?.map(m => m?.isEnabledFlag)[0];

  const disableLxpSSO =
    featureflaglist &&
    (featureflaglist?.filter(d => d?.flagName === disableLxpSSOFlag)?.map(m => m?.isEnabledFlag)[0] as Readonly<boolean> | undefined);

  const disableProjectReadiness =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableProjectReadinessFlag)?.map(m => m?.isEnabledFlag)[0];

  const disableFrenchEpic =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableFrenchEpicFlag)?.map(m => m?.isEnabledFlag)[0];
  const disableGermanEpic =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableGermanEpicFlag)?.map(m => m?.isEnabledFlag)[0];

  const enableCopilot = featureflaglist && featureflaglist?.filter(d => d?.flagName === enableCopilotV2)?.map(m => m?.isEnabledFlag)[0];
  const disableELAUnAuthFlow =
    featureflaglist && featureflaglist?.filter(d => d?.flagName === disableELAUnAuthFlowFlag)?.map(m => m?.isEnabledFlag)[0];
  return {
    isSigninDisable,
    isMaintenanceMode,
    searchFeature,
    showNewTimezone,
    hideSessionTimeout,
    isMultiRoleAndFiltersInURLDisabled,
    hideULJPhase2,
    isExtraSearchODataFiltersEnabled,
    hideBLSessions,
    isBLTeamsJoinLink,
    isResendInvitationLink,
    disableEsiTerms,
    disableLearnSSOForCertificationPage,
    disableLearnSSOForDeliveriesPage,
    disableLearnSSOForLandingPageAndNavs,
    disableLxpSSO,
    disableProjectReadiness,
    disableFrenchEpic,
    disableGermanEpic,
    enableCopilot,
    disableELAUnAuthFlow
  };
};

export default FeatureFlagValues;
