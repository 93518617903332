import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  TeachingBubble,
  PrimaryButton,
  DirectionalHint,
  IButtonProps,
  ITeachingBubbleStyles,
  IImageProps,
  FontIcon
} from '@fluentui/react';
import { useHistory, useLocation } from 'react-router';
import { useId } from '@fluentui/react-hooks';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { ICalloutProps } from 'office-ui-fabric-react';

import FeatureFlagValues from '../../utilities/featureFlagValues';
import { SearchHelper } from '../../utilities/SearchHelper';
import { GlobalResults } from './GlobalResults';
import './SearchTextBox.scss';
import { RouteErrorState } from '../../models';
import { IApplicationState } from '../../store';
import { useLocale } from '../LocalizationProvider/LocalizationProvider';
import { Helper } from '../../utilities/Helper';
import SearchBubbleIcon from '../../assets/images/SearchBubbleIcon.png';
import { useAccessToken } from '../../hooks/useAccessToken';
import '../../styles/variables.scss';

interface IGlobalSearchProps {
  defaultUrl: string;
  searchIcon?: string;
  notGlobal?: boolean;
  onContinue?: any;
  isHamburger?: boolean;
}

/**
 * Search component
 *
 * @param props - The properties
 */
export const SearchTextBox: React.FunctionComponent<IGlobalSearchProps> = props => {
  const searchFeature = FeatureFlagValues().searchFeature;
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const [shouldSearch, setShouldSearch] = useState(false);
  const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);

  const locale = useLocale();
  const [profile] = useSelector((state: IApplicationState) => [state.UserStore.profile], shallowEqual);
  const searchBoxId = useId('searchId');
  const [items, setItems] = useState(['']);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [teachingBubbleClose, setTeachingBubbleClose] = useState(false);
  const searchBox = document.getElementById(searchBoxId);
  const location = useLocation<RouteErrorState>();
  const currentPage = location.pathname;
  const showBubble = currentPage === '/landing';
  const exampleImageProps: IImageProps = { src: SearchBubbleIcon, alt: 'Search Bubble Icon', imageFit: 1 };
  const intl = useIntl();
  const accessToken = useAccessToken();
  const calloutStyles: ICalloutProps['styles'] = {
    beak: {
      background: '#243b5d'
    }
  };

  const bubbleStyles: Partial<ITeachingBubbleStyles> = {
    root: {
      display: 'flex',
      flexDirection: 'right',
      top: '5.5em',
      margin: '1.5em'
    },
    footer: {
      [`& > .ms-StackItem`]: {
        display: 'flex',
        flexDirection: 'row-reverse'
      },
      [`& > .ms-StackItem > .ms-Button`]: {
        margin: '0 15em 0 0',
        ':focus': {
          color: '#ffffff',
          backgroundColor: '#0069D9'
        }
      }
    },

    imageContent: {
      [`& > .ms-Image`]: {
        position: 'absolute',
        top: '6.8em',
        right: '1.8em',
        width: '7.8em',
        height: '7.8em',
        background: 'white',
        padding: '1.25em',
        borderRadius: '0.75em'
      }
    },
    content: {
      animationFillMode: 'none',
      animationDuration: '0',
      backgroundColor: '#243b5d'
    },

    body: {
      [`& > .ms-TeachingBubble-subText`]: {
        width: '18em'
      }
    },
    bodyContent: {
      minHeight: 'max-content',
      minWidth: 'max-content',
      overflowWrap: 'anywhere'
    },
    subComponentStyles: {
      callout: calloutStyles
    }
  };

  let searchQuery = new URLSearchParams(location.search).get('q') ?? '*';

  if (searchQuery === '*') {
    searchQuery = '';
  }

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  const doSearch = useCallback(() => {
    if (props?.onContinue) {
      props?.onContinue(true);
    }
    setShowSuggestions(false);
    const split = searchValue?.split('|');
    const finalSearchValue = split[0].trim();
    const loc = props.defaultUrl;
    if (finalSearchValue !== null && finalSearchValue !== '') {
      setShouldSearch(false);
      Helper.setClarityCustomTag('Search', finalSearchValue);
      setSearchValue(finalSearchValue);
      history.push(`${loc}?q=${finalSearchValue}`);
    } else {
      alert('Cannot search for empty string!');
    }
  }, [history, props, searchValue]);

  const headline = intl.formatMessage({ defaultMessage: 'Try LxP search!' });
  const bodyText = intl.formatMessage({ defaultMessage: 'Simply enter a course or certification name or code! Search examples:' });
  const exOption0 = intl.formatMessage({ defaultMessage: 'Blended' });
  const exOption1 = intl.formatMessage({ defaultMessage: 'Azure Administrator' });
  const exOption2 = intl.formatMessage({ defaultMessage: 'AZ-104' });
  const exOption3 = intl.formatMessage({ defaultMessage: 'Security Administrator' });
  const exOption4 = intl.formatMessage({ defaultMessage: 'AZ-900' });

  const inputRef = useRef<HTMLInputElement>(null);

  const onDismiss = useCallback(event => {
    setTeachingBubbleVisible(false);
    setTeachingBubbleClose(true);
  }, []);

  const examplePrimaryButtonProps: IButtonProps = React.useMemo(
    () => ({
      children: 'Got It!',
      onClick: onDismiss
    }),
    [onDismiss]
  );

  const getSuggestions = async (query: string) => {
    const suggestions = await SearchHelper.GetDeliverySuggestions(query, profile.TPID, locale.locale, accessToken);
    setItems(suggestions);
    setShowSuggestions(true);
  };

  useEffect(() => {
    if (shouldSearch) {
      doSearch();
    }
  }, [doSearch, shouldSearch]);

  useEffect(() => {
    if (teachingBubbleClose) {
      inputRef.current?.focus();
    }
  }, [teachingBubbleClose]);

  const onResults = useCallback(
    (item: string) => {
      setSearchValue(item);
      searchBox?.focus();
      setShowSuggestions(false);
      setShouldSearch(true);
    },
    [searchBox]
  );

  const addSuggestion = useCallback(
    event => {
      const suggestions = document.getElementsByClassName('item');
      const suggestionIndex = suggestions && suggestions.length - 1;
      const currentLi = event.target as HTMLLIElement;
      const prevId = currentLi.id === '0' ? suggestionIndex : parseInt(currentLi.id) - 1;
      const nextId = currentLi.id === suggestionIndex.toString() ? 0 : parseInt(currentLi.id) + 1;
      const { key } = event;
      if (key === 'ArrowDown') {
        event.preventDefault();
        (suggestions[nextId] as HTMLLIElement)?.focus();
      }
      if (key === 'ArrowUp') {
        event.preventDefault();
        (suggestions[prevId] as HTMLLIElement)?.focus();
      }
      if (key === 'Enter') {
        onResults(currentLi.innerHTML);
      }
    },
    [onResults]
  );

  const handleSearchArrow = useCallback(event => {
    const { key } = event;
    if (key === 'ArrowDown') {
      event.preventDefault();
      const search = event.target as HTMLInputElement;
      const firstLi = document.getElementsByClassName('item')[0] as HTMLLIElement;
      if (search) {
        firstLi?.focus();
      }
    }
  }, []);

  useEffect(() => {
    const search = document.getElementById(searchBoxId) as HTMLInputElement;
    if (search) {
      search.addEventListener('keydown', handleSearchArrow);
    }

    return () => {
      search?.removeEventListener('keydown', handleSearchArrow);
    };
  }, [handleSearchArrow, searchBoxId]);

  const clickHandler = useCallback(event => {
    setShowSuggestions(false);
  }, []);

  useEffect(() => {
    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  });
  const handleKeyDown = (event?: React.KeyboardEvent<HTMLInputElement | undefined>) => {
    if (event?.key === 'Enter') {
      doSearch();
    } else if (event?.key === 'ArrowDown') {
      event.preventDefault();
      const search = event.target as HTMLInputElement;
      const firstLi = document.getElementsByClassName('item')[0] as HTMLLIElement;
      if (search) {
        firstLi?.focus();
      }
    }
  };

  let timeout: NodeJS.Timeout;
  const onChangeSearchField = (event?: React.ChangeEvent<HTMLInputElement | undefined>) => {
    const value = event?.target?.value ?? '';
    setSearchValue(value);
    clearTimeout(timeout);
    if (value.length < 1) {
      setShowSuggestions(false);
      return;
    }
    setTeachingBubbleVisible(false);
    timeout = setTimeout(() => {
      getSuggestions(value);
    }, 500);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleOnFocus = () => {
    setShowSearchIcon(false);
  };

  const handleFocusOut = () => {
    setShowSearchIcon(true);
  };

  const resetVal = () => {
    setSearchValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onEscapeKeyPress = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 27) {
      setShowSuggestions(false);
    }
  };

  // const isActiveSearchBox = document.querySelector('input') === document.activeElement;

  return (
    <React.Fragment>
      {searchFeature && (
        <div className="searchParent">
          <form className="searchBoxContainer" onSubmit={handleSubmit}>
            {showSearchIcon && (
              <div className="iconContainerSearchBox">
                <FontIcon className="iconSearchBox" iconName="Search" />
              </div>
            )}
            <label htmlFor="searchBoxId" className="ms-screenReaderOnly hideScreenReader">
              Search
            </label>
            <input
              ref={inputRef}
              id={searchBoxId}
              value={searchValue}
              name="Search"
              autoComplete="off"
              type="text"
              onChange={onChangeSearchField}
              onKeyDown={handleKeyDown}
              aria-label="SearchBox"
              placeholder="Search"
              className="inputSearchBox"
              onFocus={handleOnFocus}
              onBlur={handleFocusOut}
              onKeyUp={onEscapeKeyPress}
            />
            {searchValue.length >= 1 && <input aria-label="Close" className="clearButton" type="button" onClick={resetVal} value="X" />}
          </form>

          {props.notGlobal && <PrimaryButton text="Search" onClick={doSearch} />}
          <div className="searchChild" aria-live="polite">
            {showSuggestions && (
              <GlobalResults
                data={items}
                onResults={onResults}
                addSuggestion={addSuggestion}
                targetId={searchBoxId}
                hideSuggestions={clickHandler}
              ></GlobalResults>
            )}
          </div>
        </div>
      )}

      {teachingBubbleVisible && showBubble && (
        <div role="dialog" className="showTeach">
          <TeachingBubble
            hasCondensedHeadline={true}
            target={`#${searchBoxId}`}
            hasCloseButton={true}
            isWide={true}
            styles={bubbleStyles}
            calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
            closeButtonAriaLabel="Close"
            onDismiss={onDismiss}
            illustrationImage={exampleImageProps}
            primaryButtonProps={examplePrimaryButtonProps}
          >
            <h4 className="teachingBubbleHeader">{headline}</h4>
            <ul>
              <li> {bodyText} </li>
              <li> &nbsp; </li>
              <li> {exOption0} </li>
              <li> {exOption1} </li>
              <li> {exOption2} </li>
              <li> {exOption3} </li>
              <li> {exOption4} </li>
            </ul>
          </TeachingBubble>
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchTextBox;
